import { StatusOptionType } from 'app/components/Engagements/EngagementsModals/UpdateEngagementStatusModal';
import {
  BotSchema,
  CampaignWorkflowSchema,
  DecoratedCrmIntegrationSchema,
  QualificationRuleItemSchema,
} from 'app/schemas';
import { CustomIntentSchema } from 'app/schemas/customIntent';
import { capitalize, upperCase } from 'lodash';
import { ResourceSchema } from '../app/schemas/resource';

export const COMPANY_NAME = '6sense';
export const SHORT_COMPANY_NAME = 'Conversational Email';
export const FULL_COMPANY_NAME = `${COMPANY_NAME} ${SHORT_COMPANY_NAME}`;
const ENV = process.env.REACT_APP_APP_ENV || 'dev3';

const SESSION_COOKIE_DOMAIN = ENV === 'prod' ? '6sense.com' : '6si.com';
const HOST = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : `https://engage.${ENV}.conversations.${SESSION_COOKIE_DOMAIN}`;
const NAMESPACE = `api/v1`;
const V2_NAMESPACE = `api/v2`;
export const ENDPOINT = `${HOST}/${NAMESPACE}`;
export const V2_ENDPOINT = `${HOST}/${V2_NAMESPACE}`;
export const TEST_DOWNLOAD = 'test_download'; //Used to prevent downloading of files in test environments

export const DEFAULT_INBOX_WARMUP_DAILY_LIMIT = {
  dormant: 20,
  active: 100,
  healthy: 200,
};

const LEAD_LABEL = 'contact';
export function leadLabel({
  plural = false,
  capitalized = false,
  upCase = false,
}: {
  capitalized?: boolean;
  plural?: boolean;
  upCase?: boolean;
} = {}) {
  let label = LEAD_LABEL;
  if (plural) {
    return upCase ? `${upperCase(label)}S` : capitalized ? `${capitalize(label)}s` : `${label}s`;
  } else {
    return upCase ? upperCase(label) : capitalized ? capitalize(label) : label;
  }
}

export const UPDATABLE_TERMINAL_OPTIONS = [
  'not_interested',
  'not_interested_now',
  'qualified',
  'bounced',
  'canceled',
];
export const TERMINAL_ENGAGEMENT_STATUS_OPTIONS = [
  {
    text: 'Not interested',
    value: 'not_interested',
    classificationValue: 'negative_intent' as const,
  },
  {
    text: 'Not interested now',
    value: 'not_interested_now',
    classificationValue: 'not_interested_now' as const,
  },
  {
    text: 'Qualified',
    value: 'qualified',
    classificationValue: 'qualified_positive_intent_(goal_conversion)' as const,
  },
];
export const ENGAGEMENT_STATUS_OPTIONS: StatusOptionType[] = TERMINAL_ENGAGEMENT_STATUS_OPTIONS.map(
  ({ value, text, classificationValue }, key) => ({
    key,
    value,
    text,
    content: text,
    classificationValue,
  })
);

// used by the API to signify eternal dates or something
export const SPECIAL_START_DATE = '0001-01-01T00:00:00.000Z';
export const SPECIAL_END_DATE = '3000-01-01T00:00:00.000Z';
export const enrolmentErrorLabels: { [k: string]: string } = {
  missingMergeTags: 'Empty merge tags',
  doNotContact: 'Marked as Do Not Contact',
  inCurrentCampaign: 'Already enrolled in this campaign',
  inCurrentTopic: 'Already enrolled in this topic',
  missingMergeTagsWithoutFallback: 'Empty merge tags without fallback',
  ongoingEngagement: 'Has an ongoing engagement',
  inSuppressionLists: 'Excluded due to suppression lists',
  pausedCampaign: 'Campaign is paused',
};

export const MAX_TOTAL_ATTACHMENTS_SIZE = 5000000;

export const LEAD_IMPORT_LABELS: { [key: string]: any } = {
  noLeadOwner: `No ${LEAD_LABEL} owner`,
  duplicateRow: 'Rows with duplicate',
  invalidLeadOwner: `Owner not in ${COMPANY_NAME}`,
  noAccountName: 'No account name',
  invalidEmail: 'Invalid email',
  missingFirstName: 'Missing first name',
  invalidFirstName: 'Improper first name',
  existingEmail: `Email exists in ${COMPANY_NAME}`,
  existingCrmObject: `CRM object ID exists in ${COMPANY_NAME}`,
  exceedLeadImportCount: 'Exceeds import limit',
  existingEmailFromDiffCrmObject: `Duplicated email across CRM ${leadLabel({ plural: true })}`,
  existingIntegrationLeadWithDiffEmail: `${leadLabel({
    capitalized: true,
  })}'s email has changed on Salesforce`,
  invalidLeadOwnerEmail: `Invalid ${LEAD_LABEL} owner email`,
  emailDomainSharedWithBot: 'Email domain shared with AI Assistant',
  emailSharedWithSalesRep: 'Email shared with sales rep',
};

/** CAMPAIGN_NAME_ERROR - Specific error message we expect for duplicate name error */
export const CAMPAIGN_NAME_ERROR = 'Name has already been taken';

export const PERSISTED_OPTIONS = ['fill_blank_values', 'overwrite', 'skip_existing_leads'];
export const PERSISTED_OPTIONS_LABELS = {
  fill_blank_values: 'Update missing fields',
  overwrite: 'Overwrite existing fields',
  skip_existing_leads: `Don’t import duplicated ${leadLabel({ plural: true })}`,
};

export const NO_CONTROLS_ROUTES: { [key: string]: any } = {
  'campaign_launcher/details/preview': true,
  'campaigns/preview': true,
  'oauth2/gmail/callback': true,
  'oauth2/outlook/callback': true,
  assign_human_review_task: true,
  cancel_human_review_task: true,
  confirmation_email_sent: true,
  continue_human_review_task: true,
  conversation_flow: true,
  email_confirmation: true,
  hello: true,
  integration_reconnected: true,
  reset_password: true,
  set_password: true,
  lexical_playground: true,
  update_human_review_task: true,
};
export const EXCLUDED_TOPIC_RULES = [1, 2, 3, 433, 434, 435]; // apparently required for ancient magic. possibly not needed anymore
/**
 * Aside from custom rules, we also want to filter out deprecated standard topic rules.
 * Since these topic rules aren't consistent across environments, we can't rely on ids and have to filter through string matching
 */
export const DEPRECATED_TOPIC_RULES = [
  'Request For Information (Pricing)',
  'Using Other Solution',
  'Not The Right Person',
  'Not A Good Time',
];
export const PER_PAGE = 10;
export const TEXT_LIMIT = 1000;
export const TEAM_CONTENT_SAMPLES_LIMIT = 20;
export const CAMPAIGN_CONTENT_SAMPLES_FOR_TONE_LIMIT = 10;
export const FOLLOW_UP_EMAIL_TRIGGER_DURATION = 3;

export const REVIEW_ENROLLMENT_LABELS: { [key: string]: any } = {
  missingMergeTags: 'Missing value for merge tag',
  doNotContact: 'Marked as Do Not Contact',
  inCurrentCampaign: 'Already enrolled in this campaign',
  inCurrentTopic: 'Already enrolled in this topic',
  missingMergeTagsWithoutFallback: 'Empty merge tags without fallback',
  ongoingEngagement: 'Has an ongoing engagement',
  inSuppressionLists: 'Excluded due to suppression lists',
  pausedCampaign: 'Campaign is paused',
};

export const ACCOUNT_SOURCE_LABELS = [
  // used for accounts table and accountDetailsContainer
  { source: 'app', label: COMPANY_NAME, icon: 'SixSenseLogo' },
  { source: 'salesforce', label: 'Salesforce', icon: 'integration-salesforce' },
  { source: 'spreadsheet', label: 'CSV', icon: '' },
  { source: 'zapier', label: 'Zapier', icon: 'Zapier' },
  { source: 'api', label: 'API', icon: '' },
];

export const RESOURCE_TYPE_LABELS = {
  lead: LEAD_LABEL,
  engagement: 'conversations',
};

export const emptyMeta = {
  currentPage: 1,
  nextPage: null,
  prevPage: null,
  totalPages: 1,
  totalCount: 0,
};

export interface DropdownOptionsSchema {
  key: string;
  text: string;
  value: string | null;
}
export const MEMBER_TITLE_OPTIONS: DropdownOptionsSchema[] = [
  { text: 'Marketing', key: 'marketing', value: 'marketing' },
  { text: 'Sales', key: 'sales', value: 'sales' },
  { text: 'Operations', key: 'operations', value: 'operations' },
  { text: 'Founder', key: 'founder', value: 'founder' },
  { text: 'Company Management', key: 'company_management', value: 'company_management' },
  { text: 'Student Recruitment', key: 'student_recruitment', value: 'student_recruitment' },
  { text: 'Consultant/Professional services', key: 'consultant', value: 'consultant' },
  { text: 'IT services', key: 'it_services', value: 'it_services' },
  { text: 'Others', key: 'others', value: 'others' },
];

export const ADDITIONAL_RESOURCE_LIST = [
  {
    titleText: 'Resource center',
    descriptionText: `Visit our help center page to learn more about ${FULL_COMPANY_NAME} and its features.`,
    redirectUrl: '/resources',
  },
  {
    titleText: `${FULL_COMPANY_NAME} support`,
    descriptionText: `Visit our support page to get answers and advice from the ${FULL_COMPANY_NAME} team for all our features.`,
    redirectUrl: 'https://support.saleswhale.com',
  },
];
export const SHORT_RESOURCE_LIST = [
  {
    titleText: `${FULL_COMPANY_NAME} training flow`,
    descriptionText: 'Overview of the training flow and resources available.',
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense-conversation-email-training-flow-new.png',
    diagramUrl:
      'https://6sense.com/wp-content/uploads/2023/02/6sense%C2%AE-Conversational-Email-Training-Flow.pdf',
    slug: 'conversational-email-training',
  },
  {
    titleText: 'Admin setup guide',
    descriptionText: `A one-page guide to the set up required prior to using ${FULL_COMPANY_NAME}.`,
    thumbnailUrl: 'https://static.saleswhale.com/media/6sense-conversational-email-admin-setup.png',
    diagramUrl:
      'https://6sense.com/wp-content/uploads/2022/10/6sense%C2%AE-Conversational-Email-Admin-Setup.pdf',
    slug: 'admin-setup-guide',
  },
  {
    titleText: `${FULL_COMPANY_NAME} diagram`,
    descriptionText:
      'An overview of the steps needed from admin setup to reviewing campaign results.',
    thumbnailUrl: 'https://static.saleswhale.com/media/6sense-conversation-email.png',
    diagramUrl:
      'https://6sense.com/wp-content/uploads/2022/10/6sense%C2%AE-Conversational-Email-Diagram.pdf',
    slug: 'conversational-email-diagram',
  },
];
export const RESOURCE_LIST: ResourceSchema[] = [
  ...SHORT_RESOURCE_LIST,
  {
    titleText: `${FULL_COMPANY_NAME} at a Glance`,
    descriptionText: `In this video, Sam Liu, Director of Customer Success explains ${FULL_COMPANY_NAME}, how it's used, provides a brief overview of the platform, and shows Conversational Email in action.`,
    thumbnailUrl: 'https://embed-ssl.wistia.com/deliveries/57a4afb2dc3bb29f5ce34bd211810317.jpg',
    videoUrl: 'jp3p6aysqo',
    slug: 'conversational-email-at-a-glance',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Campaign Prep & Maintenance`,
    descriptionText:
      'In this Video, Sam Liu, Director of Customer Success takes you through the preparation activities before setting up a campaign.',
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense%C2%AE-Conversational-Email-Admin-Setup.png',
    videoUrl: 'xtsjp718tp',
    slug: 'campaign-prep-and-maintenance',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Playbook Guide`,
    descriptionText:
      'A guide for revenue teams on creating & converting more pipeline using generative AI​',
    thumbnailUrl: 'https://static.saleswhale.com/rep/media/playbook-guide.jpg',
    diagramUrl: 'https://6sense.com/wp-content/uploads/2023/06/Conversational-Email-Playbook.pdf',
    slug: 'playbook-guide',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Setting up & Launching a Campaign`,
    descriptionText:
      'In this video, Sam Liu, Director of Customer Success walks you through how to set up and launch a campaign.',
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense%C2%AE-Conversational-Email-Admin-Setup.png',
    videoUrl: 'ogjm51ihm8',
    slug: 'campaign-set-up-and-launch',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Getting Started with the AI Writer`,
    descriptionText:
      'In this video, Marcel Tan, Product Manager takes you through how to onboard the AI Writer.',
    thumbnailUrl: '',
    videoUrl: 'ebai26ztx6',
    slug: 'getting-started-with-ai-writer',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Editing & Using the AI Writer's Emails`,
    descriptionText:
      'In this video, Marcel Tan, Product Manager takes you through how to customize and use the emails generated by the AI Writer.',
    thumbnailUrl: '',
    videoUrl: 'u2rrnpmge8',
    slug: 'editing-and-using-ai-writer-emails',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - AI Writer Best Practices`,
    descriptionText:
      'In this video, Marcel Tan, Product Manager takes you through the best practices for working with the AI Writer, including prompt engineering.',
    thumbnailUrl: '',
    videoUrl: 'kj2kgdzer9',
    slug: 'ai-writer-best-practices',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Setting up & Launching a Campaign`,
    descriptionText:
      'In this video, Sam Liu, Director of Customer Success walks you through how to set up and launch a campaign.',
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense%C2%AE-Conversational-Email-Admin-Setup.png',
    videoUrl: 'ogjm51ihm8',
    slug: 'campaign-set-up-and-launch',
  },
  {
    titleText: `${FULL_COMPANY_NAME} - Results & Review`,
    descriptionText:
      'In this video, Sam Liu, Director of Customer Success shares how to analyze campaign and AI metrics and manage human review tasks.',
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense%C2%AE-Conversational-Email-Admin-Setup.png',
    videoUrl: 'ktj0iqrxzc',
    slug: 'campaign-results-and-review',
  },
  {
    titleText: 'Post 30 Days Campaign Checklist',
    descriptionText:
      "What's next? After 30 days of running campaigns, explore orchestrations, workflows, and plan your next campaign(s).",
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense-conversation-email-post-campaign-checklist.png',
    diagramUrl:
      'https://6sense.com/wp-content/uploads/2022/10/6sense%C2%AE-Conversational-Email-Post-30-Days-Checklist.pdf',
    slug: 'campaign-30-days-checklist',
  },
  {
    titleText: `${FULL_COMPANY_NAME} for Sales Reps`,
    descriptionText:
      'Share this one-pager with your sales team to get them up to speed on Conversational Email, how it works and how it helps both teams.',
    thumbnailUrl:
      'https://static.saleswhale.com/media/6sense-conversational-email-for-sales-reps.png',
    diagramUrl:
      'https://6sense.com/wp-content/uploads/2022/10/6sense%C2%AE-Conversational-Email-for-Sales-Reps.pdf',
    slug: 'conversation-email-for-sales-reps',
  },
  {
    titleText: 'FAQ',
    descriptionText: 'Most asked questions answered here.',
    thumbnailUrl: 'https://static.saleswhale.com/media/6sense-conversational-email-faq.png',
    diagramUrl:
      'https://6sense.com/wp-content/uploads/2022/10/6sense%C2%AE-Conversational-Email-FAQ.pdf',
    slug: 'conversational-email-faq',
  },
];

/**
 * copies for mapping types to file upload component
 */
export const ALL_UPLOAD_TYPE = {
  fileDescription: '',
  fileAccept:
    'application/pdf, image/*, application/zip, application/octet-stream, text/plain, .csv',
};
export const CSV_DOC_UPLOAD_TYPE = {
  fileDescription: 'CSV',
  fileAccept: '.csv, application/xls, application/octet-stream, text/csv',
};
export const TXT_DOC_UPLOAD_TYPE = {
  fileDescription: 'PDF, DOC, DOCX, or TXT',
  fileAccept: '.pdf, .doc, .docx, .txt',
};

/**
 * Used for mapping icons to file types
 */
export const DOC_TYPES = ['csv', 'doc', 'key', 'mdb', 'num', 'page', 'pdf', 'ppt', 'txt', 'xls'];
export const IMAGE_TYPES = ['gif', 'jpg', 'png', 'svg'];
export const MISC_TYPES = ['zip', 'file'];

export const CLOSING_DIV_TAG_LENGTH = 6;

export const DOMAIN_LIMIT = 10;

export const PLAYGROUND_EMAIL_IGNORE_MESSAGE = 'User has chosen to ignore email';

export type AiWriterWelcomeOnboardingVideo = { id: string; title: string };
export const AI_WRITER_WELCOME_ONBOARDING_VIDEOS: AiWriterWelcomeOnboardingVideo[] = [
  {
    id: 'ebai26ztx6',
    title: 'Getting Started',
  },
  {
    id: 'u2rrnpmge8',
    title: 'Editing and Using Emails',
  },
];
export const INTENT_CLASSIFICATIONS = {
  positive_intent: {
    label: 'Contact is Interested',
    description: 'Contact shows an interest in the offering.',
  },
  'qualified_positive_intent_(goal_conversion)': {
    label: 'Contact is Interested',
    description: 'Contact shows an interest in the offering.',
  },
  negative_intent: {
    label: 'Contact is Not Interested',
    description: 'Contact is not interested in the offering.',
  },
  not_interested_now: {
    label: 'Contact is Not Interested Now',
    description:
      'Contact is not interested specifically at this point of time, but might be in the future.',
  },
  'request_for_information_(generic)': {
    label: 'Contact Requests for Information',
    description: 'Contact requests for more information about the offering or company.',
  },
  dnc: {
    label: 'Do Not Contact',
    description: 'Contact wants to be removed from the mailing list.',
  },
  not_the_right_person: {
    label: 'Not the Right Person',
    description: 'Contact is not a decision maker or in the relevant department.',
  },
  referral: {
    label: 'Referral',
    description: 'Contact refers another person or department.',
  },
  out_of_office: {
    label: 'Out of Office',
    description: 'Contact mentions that they are currently on leave.',
  },
  bounced: {
    label: 'Bounced',
    description: 'Contact has left the company or email address is no longer in use.',
  },
  neutral_intent: {
    label: 'Neutral Intent',
    description:
      'Emails that have no clear expression of intent e.g., gibberish, automated response emails.',
  },
  unknown_intent: {
    label: 'Unknown Intent',
    description: 'The intent is unclear or there are mixed intents.',
  },
} as const;

export const CAMPAIGN_PRIORITY = { high: 9, low: 0 };

export const TRAINING_CONTENT_CONTENT_TYPE_OPTIONS = [
  'Case study',
  'One pager',
  'Product overview',
  'Blog post',
  'Email',
  'Battlecard',
  'White paper',
  'Others',
];

export const ROLE_LABELS = {
  admin: 'Administrator',
  member: 'Marketing User',
};

export const MAX_NUM_OF_TRAINING_CONTENTS = 20;
export const MAX_NUM_OF_SELECTED_TRAINING_CONTENTS = 5;

export const CUSTOM_INTENTS: CustomIntentSchema[] = [
  {
    name: 'Is already an Airtable customer.',
    description: 'This outlines a strategy to upsell to leads ... for our product.',
    replyStrategy: 'some talking point',
    callToAction: 'cc_sales_rep',
  },
  {
    name: 'Moved away from Airtable.',
    description: 'This outlines a strategy to potentially rescue ... rescue the deal.',
    replyStrategy: 'some talking point',
    callToAction: 'continue_conversation',
  },
];

// used to render the intents when AI powered replies is turned on. hardcoding this for now temporarily, but eventually we'll move to a rendering two separate trees.
export const AI_INTENTS = [
  {
    id: 97311,
    teamId: 1,
    updatedAt: '2024-01-22T05:49:31.694Z',
    qualificationRuleId: 33667,
    topicRule: {
      id: 1,
      teamId: null,
      ruleType: 'standard',
      nextState: 'qualified',
      name: 'Qualified Positive Intent (Goal Conversion)',
      displayName: 'Contact is Qualified',
      description: '',
      selectable: false,
    },
    sequence: {
      id: 108033,
      teamId: 1,
      isValid: true,
      conversationTopicVersionId: 10344,
      conversationTopicVersionSettings: {},
      conversationTopicId: 5962,
      terminalState: 'qualified',
      snippets: {
        referralOptIn:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
        referralOptOut:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
      },
      hasReferralSnippets: true,
      createdAt: '2024-01-22T05:49:31.630Z',
      nextState: 'qualified',
      displayName: 'Contact is Qualified',
      highlightedAt: '2024-01-22T05:49:31.660Z',
      isRoot: false,
      topicRuleId: 1,
      sequenceTouchPoints: [],
      qualificationRule: null,
      orderedSequenceTouchPoints: [],
      didUpdate: false,
      shouldHighlight: false,
      isContinue: false,
      isReferral: false,
      isQualified: true,
      isNotInterested: false,
      isNotInterestedNow: false,
      isTerminal: true,
      isNotTerminal: false,
      hasChildren: false,
      hasFollowUpEmail: false,
    },
  },
  {
    id: 97312,
    teamId: 1,
    updatedAt: '2024-01-22T05:49:31.762Z',
    qualificationRuleId: 33667,
    topicRule: {
      id: 2,
      teamId: null,
      ruleType: 'standard',
      nextState: 'not_interested',
      name: 'Negative Intent',
      displayName: 'Contact is Not Interested',
      description: '',
      selectable: false,
    },
    sequence: {
      id: 108034,
      teamId: 1,
      isValid: true,
      conversationTopicVersionId: 10344,
      conversationTopicVersionSettings: {},
      conversationTopicId: 5962,
      terminalState: 'not_interested',
      snippets: {
        referralOptIn:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
        referralOptOut:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
      },
      hasReferralSnippets: true,
      createdAt: '2024-01-22T05:49:31.714Z',
      nextState: 'not_interested',
      displayName: 'Contact is Not Interested',
      highlightedAt: '2024-01-22T05:49:31.736Z',
      isRoot: false,
      topicRuleId: 2,
      sequenceTouchPoints: [],
      qualificationRule: null,
      orderedSequenceTouchPoints: [],
      didUpdate: false,
      shouldHighlight: false,
      isContinue: false,
      isReferral: false,
      isQualified: false,
      isNotInterested: true,
      isNotInterestedNow: false,
      isTerminal: true,
      isNotTerminal: false,
      hasChildren: false,
      hasFollowUpEmail: false,
    },
  },
  {
    id: 97313,
    teamId: 1,
    updatedAt: '2024-01-22T05:49:31.886Z',
    qualificationRuleId: 33667,
    topicRule: {
      id: 46,
      teamId: null,
      ruleType: 'standard',
      nextState: 'not_interested_now',
      name: 'Not Interested Now',
      displayName: 'Contact is Not Interested Now',
      description:
        'Your contact is not interested now but is open for re-engagement in the future.',
      selectable: false,
    },
    sequence: {
      id: 108035,
      teamId: 1,
      isValid: true,
      conversationTopicVersionId: 10344,
      conversationTopicVersionSettings: {},
      conversationTopicId: 5962,
      terminalState: 'not_interested_now',
      snippets: {
        referralOptIn:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
        referralOptOut:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
      },
      hasReferralSnippets: true,
      createdAt: '2024-01-22T05:49:31.773Z',
      nextState: 'not_interested_now',
      displayName: 'Contact is Not Interested Now',
      highlightedAt: '2024-01-22T05:49:31.871Z',
      isRoot: false,
      topicRuleId: 46,
      sequenceTouchPoints: [],
      qualificationRule: null,
      orderedSequenceTouchPoints: [],
      didUpdate: false,
      shouldHighlight: false,
      isContinue: false,
      isReferral: false,
      isQualified: false,
      isNotInterested: false,
      isNotInterestedNow: true,
      isTerminal: true,
      isNotTerminal: false,
      hasChildren: false,
      hasFollowUpEmail: false,
    },
  },
  {
    id: 97584,
    teamId: 1,
    updatedAt: '2024-01-23T04:26:52.950Z',
    qualificationRuleId: 33735,
    topicRule: {
      id: 5,
      teamId: null,
      ruleType: 'standard',
      nextState: 'continue',
      name: 'Request For Information (Generic)',
      displayName: 'Contact Requests for Information',
      description: 'Your contact asks for more details about your product or service.',
      selectable: true,
    },
    sequence: {
      id: 108320,
      teamId: 1,
      isValid: false,
      conversationTopicVersionId: 10358,
      conversationTopicVersionSettings: {},
      conversationTopicId: 5962,
      terminalState: 'continue',
      snippets: {
        referralOptIn:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
        referralOptOut:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
      },
      hasReferralSnippets: true,
      createdAt: '2024-01-23T04:26:52.944Z',
      nextState: 'continue',
      displayName: 'Contact Requests for Information',
      highlightedAt: '2024-01-23T04:26:52.944Z',
      isRoot: false,
      topicRuleId: 5,
      sequenceTouchPoints: [],
      qualificationRule: null,
      orderedSequenceTouchPoints: [],
      didUpdate: false,
      shouldHighlight: false,
      isContinue: true,
      isReferral: false,
      isQualified: false,
      isNotInterested: false,
      isNotInterestedNow: false,
      isTerminal: false,
      isNotTerminal: true,
      hasChildren: true,
      hasFollowUpEmail: false,
    },
  },
  {
    id: 97317,
    teamId: 1,
    updatedAt: '2024-01-22T05:49:32.073Z',
    qualificationRuleId: 33667,
    topicRule: {
      id: 38,
      teamId: null,
      ruleType: 'standard',
      nextState: 'continue',
      name: 'Not The Right Person',
      displayName: 'Not the Right Person',
      description:
        'Your contact mentions they are not the right person without referring another person contact.',
      selectable: true,
    },
    sequence: {
      id: 108036,
      teamId: 1,
      isValid: true,
      conversationTopicVersionId: 10344,
      conversationTopicVersionSettings: {},
      conversationTopicId: 5962,
      terminalState: 'continue',
      snippets: {
        referralOptIn:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
        referralOptOut:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
      },
      hasReferralSnippets: true,
      createdAt: '2024-01-22T05:49:31.894Z',
      nextState: 'continue',
      displayName: 'Not the Right Person',
      highlightedAt: '2024-01-22T05:49:31.919Z',
      isRoot: false,
      topicRuleId: 38,
      sequenceTouchPoints: [],
      qualificationRule: null,
      orderedSequenceTouchPoints: [],
      didUpdate: false,
      shouldHighlight: false,
      isContinue: true,
      isReferral: false,
      isQualified: false,
      isNotInterested: false,
      isNotInterestedNow: false,
      isTerminal: false,
      isNotTerminal: true,
      hasChildren: true,
      hasFollowUpEmail: false,
    },
  },
  {
    id: 97325,
    teamId: 1,
    updatedAt: '2024-01-22T05:49:32.651Z',
    qualificationRuleId: 33667,
    topicRule: {
      id: 6,
      teamId: null,
      ruleType: 'standard',
      nextState: 'referral',
      name: 'Referral',
      displayName: 'Contact Refers Someone',
      description: 'Your contact introduces another person’s email into the thread.',
      selectable: true,
    },
    sequence: {
      id: 108040,
      teamId: 1,
      isValid: true,
      conversationTopicVersionId: 10344,
      conversationTopicVersionSettings: {},
      conversationTopicId: 5962,
      terminalState: 'referral',
      snippets: {
        referralOptIn:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
        referralOptOut:
          'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
      },
      hasReferralSnippets: true,
      createdAt: '2024-01-22T05:49:32.078Z',
      nextState: 'referral',
      displayName: 'Contact Refers Someone',
      highlightedAt: '2024-01-22T05:49:32.089Z',
      isRoot: false,
      topicRuleId: 6,
      sequenceTouchPoints: [],
      qualificationRule: null,
      orderedSequenceTouchPoints: [],
      didUpdate: false,
      shouldHighlight: false,
      isContinue: false,
      isReferral: true,
      isQualified: false,
      isNotInterested: false,
      isNotInterestedNow: false,
      isTerminal: false,
      isNotTerminal: true,
      hasChildren: true,
      hasFollowUpEmail: false,
    },
  },
] as never as QualificationRuleItemSchema[];

export const MOCK_CRM_INTEGRATION: DecoratedCrmIntegrationSchema = {
  hasError: false,
  isConnected: true,
  isDisconnected: false,
  isInterrupted: false,
  isInProgress: false,
  isOk: true,
  authenticatedUserEmail: 'test@test.com',
  connectedAt: new Date(),
  crmErrors: [],
  crmType: 'salesforce_sandbox',
  dncFieldName: 'DNC',
  id: 1,
  isAutoPushEnabled: true,
  isCrmConfigured: true,
  isFirstPackageEnabled: true,
  isSecondPackageEnabled: true,
  state: 'ok',
  teamId: 2,
  user: {
    createdAt: '',
    currentMembershipId: 1,
    email: 'user@test.com',
    firstName: 'John',
    id: 2,
    lastName: 'Doe',
    notifyOnBotDisconnect: false,
    title: 'marketing',
  },
  uuid: '',
};
export const MOCK_CAMPAIGN_WORKFLOW: CampaignWorkflowSchema = {
  createdById: 1,
  createdByName: 'Jane Doe',
  id: 3,
  isActive: true,
  workflowImportStrategy: {
    crmReport: {
      crmType: 'salesforce',
      externalId: '123',
      name: 'Sample Report',
    },
  },
  workflowStat: {
    enrolledLeadsCount: 89,
    errorLeadsCount: 1,
    remainingLeadsCount: 10,
    totalLeadsCount: 100,
    updatedAt: '2022-03-16T10:04:54.269Z',
  },
};
export const AI_BLOCKS = [
  {
    defaultContent: 'hey',
    id: 1,
    teamId: 1,
  },
];
export const BOUNCE_AVOID_TIPS: { tip: string }[] = [
  { tip: 'Clean your contacts list regularly' },
  { tip: "Make sure your emails aren't spammy" },
  { tip: "Don't use free sender domains" },
  { tip: 'Use A/B testing of emails in Campaign' },
  { tip: 'Remove hard bounce email addresses from your list' },
];

export enum PotentialBounceReason {
  ROLE_BASED_EMAIL = 'roleBasedEmail',
  FREE_EMAIL_PROVIDER = 'freeEmailProvider',
  DISPOSABLE_EMAIL = 'disposableEmail',
  INVALID_EMAIL_SERVER = 'invalidEmailServer',
}

export const POTENTIAL_BOUNCE_REASONS: {
  id: PotentialBounceReason;
  name: string;
  popupContent: string;
}[] = [
  {
    id: PotentialBounceReason.ROLE_BASED_EMAIL,
    name: 'Role-based email addresses',
    popupContent:
      'Role based email addresses are usually not associated to individuals, and might not accept any inbound emails.',
  },
  {
    id: PotentialBounceReason.FREE_EMAIL_PROVIDER,
    name: 'Personal email addresses',
    popupContent:
      'Personal emails might not be appropriate contact points, depending on the audience you would like to reach out to.',
  },
  {
    id: PotentialBounceReason.DISPOSABLE_EMAIL,
    name: 'Disposable email addresses',
    popupContent:
      'Disposable emails might self destruct in the future, and contribute to bounce rates.',
  },
  {
    id: PotentialBounceReason.INVALID_EMAIL_SERVER,
    name: 'Email server may not exist',
    popupContent:
      'Contacts might be associated with invalid domains, and any emails inbound will bounce.',
  },
];

export const MOCK_COMPANY_INFORMATION = {
  companyName: 'test name',
  companyWebsite: 'https://6sense.com',
  companyDescription: 'some description here',
  keyPersona: 'sample persona',
};

export enum BulkSelectAction {
  ALL = 'all',
  NONE = 'none',
  VISIBLE = 'visible',
}

export const MOCK_MAIN_SEQUENCE = {
  id: 120019,
  teamId: 1,
  isValid: true,
  conversationTopicVersionId: 11451,
  conversationTopicVersionSettings: {},
  conversationTopicId: 6627,
  terminalState: 'continue',
  snippets: {
    referralOptIn:
      'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
    referralOptOut:
      'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
  },
  hasReferralSnippets: true,
  createdAt: '2024-08-01T04:40:31.866Z',
  nextState: 'continue',
  displayName: 'Contact Requests for Information',
  highlightedAt: '2024-08-01T04:40:31.878Z',
  isRoot: false,
  topicRuleId: 5,
  sequenceTouchPoints: [
    {
      id: 125782,
      durationType: 'day',
      sequenceId: 120019,
      teamId: 1,
      touchPointOrder: 0,
      triggerOnDuration: 0,
      sequenceTouchPointTemplates: [
        {
          id: 126163,
          teamId: 1,
          subject: '',
          body: '<div><span style="white-space: pre-wrap;">Hi </span><merge-tag>{{lead.first_name}}</merge-tag><span style="white-space: pre-wrap;">, </span></div>',
          deliveryType: 'reply',
          snippet: 'Hi {{lead.first_name}},',
          sequenceTouchPointId: 125782,
          isActive: true,
          dynamicContents: [],
          aiBlocks: [],
          sequenceTouchPointTemplateAttachments: [],
        },
      ],
      templates: [
        {
          id: 126163,
          teamId: 1,
          subject: '',
          body: '<div><span style="white-space: pre-wrap;">Hi </span><merge-tag>{{lead.first_name}}</merge-tag><span style="white-space: pre-wrap;">, </span></div>',
          deliveryType: 'reply',
          snippet: 'Hi {{lead.first_name}},',
          sequenceTouchPointId: 125782,
          isActive: true,
          dynamicContents: [],
          aiBlocks: [],
          sequenceTouchPointTemplateAttachments: [],
          attachments: [],
        },
      ],
      isSendImmediately: true,
    },
  ],
  qualificationRule: {
    id: 36674,
    teamId: 1,
    sequenceId: 120019,
    updatedAt: '2024-08-01T04:40:31.970Z',
    conversationTopicVersionId: 11451,
    conversationTopicId: 6627,
    qualificationRuleItems: [
      {
        id: 108189,
        teamId: 1,
        updatedAt: '2024-08-01T04:40:31.916Z',
        qualificationRuleId: 36674,
        topicRule: {
          id: 1,
          teamId: null,
          ruleType: 'standard',
          nextState: 'qualified',
          name: 'Qualified Positive Intent (Goal Conversion)',
          displayName: 'Contact is Qualified',
          description: '',
          selectable: false,
        },
        sequence: {
          id: 120020,
          teamId: 1,
          isValid: true,
          conversationTopicVersionId: 11451,
          conversationTopicVersionSettings: {},
          conversationTopicId: 6627,
          terminalState: 'qualified',
          snippets: {
            referralOptIn:
              'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
            referralOptOut:
              'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
          },
          hasReferralSnippets: true,
          createdAt: '2024-08-01T04:40:31.895Z',
          nextState: 'qualified',
          displayName: 'Contact is Qualified',
          highlightedAt: '2024-08-01T04:40:31.907Z',
          isRoot: false,
          topicRuleId: 1,
          sequenceTouchPoints: [
            {
              id: 125783,
              durationType: 'day',
              sequenceId: 120020,
              teamId: 1,
              touchPointOrder: 0,
              triggerOnDuration: 0,
              sequenceTouchPointTemplates: [
                {
                  id: 126164,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125783,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                },
              ],
              templates: [
                {
                  id: 126164,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125783,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                  attachments: [],
                },
              ],
              isSendImmediately: true,
            },
          ],
          qualificationRule: null,
          orderedSequenceTouchPoints: [
            {
              id: 125783,
              durationType: 'day',
              sequenceId: 120020,
              teamId: 1,
              touchPointOrder: 0,
              triggerOnDuration: 0,
              sequenceTouchPointTemplates: [
                {
                  id: 126164,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125783,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                },
              ],
              templates: [
                {
                  id: 126164,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125783,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                  attachments: [],
                },
              ],
              isSendImmediately: true,
            },
          ],
          didUpdate: false,
          shouldHighlight: false,
          isContinue: false,
          isReferral: false,
          isQualified: true,
          isNotInterested: false,
          isNotInterestedNow: false,
          isTerminal: true,
          isNotTerminal: false,
          hasChildren: false,
          hasFollowUpEmail: false,
        },
      },
      {
        id: 108190,
        teamId: 1,
        updatedAt: '2024-08-01T04:40:31.943Z',
        qualificationRuleId: 36674,
        topicRule: {
          id: 2,
          teamId: null,
          ruleType: 'standard',
          nextState: 'not_interested',
          name: 'Negative Intent',
          displayName: 'Contact is Not Interested',
          description: '',
          selectable: false,
        },
        sequence: {
          id: 120021,
          teamId: 1,
          isValid: true,
          conversationTopicVersionId: 11451,
          conversationTopicVersionSettings: {},
          conversationTopicId: 6627,
          terminalState: 'not_interested',
          snippets: {
            referralOptIn:
              'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
            referralOptOut:
              'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
          },
          hasReferralSnippets: true,
          createdAt: '2024-08-01T04:40:31.921Z',
          nextState: 'not_interested',
          displayName: 'Contact is Not Interested',
          highlightedAt: '2024-08-01T04:40:31.934Z',
          isRoot: false,
          topicRuleId: 2,
          sequenceTouchPoints: [
            {
              id: 125784,
              durationType: 'day',
              sequenceId: 120021,
              teamId: 1,
              touchPointOrder: 0,
              triggerOnDuration: 0,
              sequenceTouchPointTemplates: [
                {
                  id: 126165,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125784,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                },
              ],
              templates: [
                {
                  id: 126165,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125784,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                  attachments: [],
                },
              ],
              isSendImmediately: true,
            },
          ],
          qualificationRule: null,
          orderedSequenceTouchPoints: [
            {
              id: 125784,
              durationType: 'day',
              sequenceId: 120021,
              teamId: 1,
              touchPointOrder: 0,
              triggerOnDuration: 0,
              sequenceTouchPointTemplates: [
                {
                  id: 126165,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125784,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                },
              ],
              templates: [
                {
                  id: 126165,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125784,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                  attachments: [],
                },
              ],
              isSendImmediately: true,
            },
          ],
          didUpdate: false,
          shouldHighlight: false,
          isContinue: false,
          isReferral: false,
          isQualified: false,
          isNotInterested: true,
          isNotInterestedNow: false,
          isTerminal: true,
          isNotTerminal: false,
          hasChildren: false,
          hasFollowUpEmail: false,
        },
      },
      {
        id: 108191,
        teamId: 1,
        updatedAt: '2024-08-01T04:40:31.968Z',
        qualificationRuleId: 36674,
        topicRule: {
          id: 47,
          teamId: null,
          ruleType: 'standard',
          nextState: 'not_interested_now',
          name: 'Not Interested Now',
          displayName: 'Contact is Not Interested Now',
          description:
            'Your contact is not interested now but is open for re-engagement in the future.',
          selectable: false,
        },
        sequence: {
          id: 120022,
          teamId: 1,
          isValid: true,
          conversationTopicVersionId: 11451,
          conversationTopicVersionSettings: {},
          conversationTopicId: 6627,
          terminalState: 'not_interested_now',
          snippets: {
            referralOptIn:
              'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it!<br><br>',
            referralOptOut:
              'Hi <snippet-merge-tag>{{original_lead.first_name}}</snippet-merge-tag>,<br><br>Thanks for connecting us. Greatly appreciate it! Moving you to BCC to avoid spamming your inbox.<br><br>',
          },
          hasReferralSnippets: true,
          createdAt: '2024-08-01T04:40:31.948Z',
          nextState: 'not_interested_now',
          displayName: 'Contact is Not Interested Now',
          highlightedAt: '2024-08-01T04:40:31.960Z',
          isRoot: false,
          topicRuleId: 47,
          sequenceTouchPoints: [
            {
              id: 125785,
              durationType: 'day',
              sequenceId: 120022,
              teamId: 1,
              touchPointOrder: 0,
              triggerOnDuration: 0,
              sequenceTouchPointTemplates: [
                {
                  id: 126166,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125785,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                },
              ],
              templates: [
                {
                  id: 126166,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125785,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                  attachments: [],
                },
              ],
              isSendImmediately: true,
            },
          ],
          qualificationRule: null,
          orderedSequenceTouchPoints: [
            {
              id: 125785,
              durationType: 'day',
              sequenceId: 120022,
              teamId: 1,
              touchPointOrder: 0,
              triggerOnDuration: 0,
              sequenceTouchPointTemplates: [
                {
                  id: 126166,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125785,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                },
              ],
              templates: [
                {
                  id: 126166,
                  teamId: 1,
                  subject: '',
                  body: '<div>Hi <merge-tag>{{lead.first_name}}</merge-tag>, </div>',
                  deliveryType: 'reply',
                  snippet: 'Hi {{lead.first_name}},',
                  sequenceTouchPointId: 125785,
                  isActive: true,
                  dynamicContents: [],
                  aiBlocks: [],
                  sequenceTouchPointTemplateAttachments: [],
                  attachments: [],
                },
              ],
              isSendImmediately: true,
            },
          ],
          didUpdate: false,
          shouldHighlight: false,
          isContinue: false,
          isReferral: false,
          isQualified: false,
          isNotInterested: false,
          isNotInterestedNow: true,
          isTerminal: true,
          isNotTerminal: false,
          hasChildren: false,
          hasFollowUpEmail: false,
        },
      },
    ],
  },
  orderedSequenceTouchPoints: [
    {
      id: 125782,
      durationType: 'day',
      sequenceId: 120019,
      teamId: 1,
      touchPointOrder: 0,
      triggerOnDuration: 0,
      sequenceTouchPointTemplates: [
        {
          id: 126163,
          teamId: 1,
          subject: '',
          body: '<div><span style="white-space: pre-wrap;">Hi </span><merge-tag>{{lead.first_name}}</merge-tag><span style="white-space: pre-wrap;">, </span></div>',
          deliveryType: 'reply',
          snippet: 'Hi {{lead.first_name}},',
          sequenceTouchPointId: 125782,
          isActive: true,
          dynamicContents: [],
          aiBlocks: [],
          sequenceTouchPointTemplateAttachments: [],
        },
      ],
      templates: [
        {
          id: 126163,
          teamId: 1,
          subject: '',
          body: '<div><span style="white-space: pre-wrap;">Hi </span><merge-tag>{{lead.first_name}}</merge-tag><span style="white-space: pre-wrap;">, </span></div>',
          deliveryType: 'reply',
          snippet: 'Hi {{lead.first_name}},',
          sequenceTouchPointId: 125782,
          isActive: true,
          dynamicContents: [],
          aiBlocks: [],
          sequenceTouchPointTemplateAttachments: [],
          attachments: [],
        },
      ],
      isSendImmediately: true,
    },
  ],
  didUpdate: false,
  shouldHighlight: false,
  isContinue: true,
  isReferral: false,
  isQualified: false,
  isNotInterested: false,
  isNotInterestedNow: false,
  isTerminal: false,
  isNotTerminal: true,
  hasChildren: true,
  hasFollowUpEmail: false,
};

export const MOCK_TOPIC_RULES = [
  {
    id: 47,
    teamId: null,
    ruleType: 'standard',
    nextState: 'not_interested_now',
    name: 'Not Interested Now',
    displayName: 'Contact is Not Interested Now',
    description: 'Your contact is not interested now but is open for re-engagement in the future.',
    selectable: false,
  },
  {
    id: 5,
    teamId: null,
    ruleType: 'standard',
    nextState: 'continue',
    name: 'Request For Information (Generic)',
    displayName: 'Contact Requests for Information',
    description: 'Your contact asks for more details about your product or service.',
    selectable: true,
  },
  {
    id: 6,
    teamId: null,
    ruleType: 'standard',
    nextState: 'referral',
    name: 'Referral',
    displayName: 'Contact Refers Someone',
    description: 'Your contact introduces another person’s email into the thread.',
    selectable: true,
  },
];

export const MAX_ALT_TEXT_COUNT = 200;

export const REWRITE_LOADING_STEPS = [
  {
    title: 'Checking playbook...',
    progress: 0,
  },
  {
    title: 'Extracting relevant information...',
    progress: 30,
  },
  {
    title: 'Analyzing data...',
    progress: 45,
  },
  {
    title: 'Strategizing...',
    progress: 60,
  },
  {
    title: 'Wordsmithing...',
    progress: 75,
  },
  {
    title: 'Some final checks...',
    progress: 90,
  },
];

export const FALLBACK_BOT = {
  id: -1,
  email: 'dolores@saleswhale.com',
  emailProvider: 'gmail',
  name: 'Dolores Ng',
  picture: '',
  createdAt: '2023-08-14T08:53:20.823Z',
  outreachEmailsSentToday: 0,
  paused: false,
  timeZone: 'Asia/Shanghai',
  signature:
    '<div><span style="white-space: pre-wrap;">yours truly,</span></div><div><span style="white-space: pre-wrap;">Charlie leclerk</span></div>',
  state: 'connected',
  useUnsubscribe: true,
  unsubscribeText:
    '<small><div><span style="white-space: pre-wrap;">To unsubscribe, </span><span style="white-space: pre-wrap;">click</span><span style="white-space: pre-wrap;"> </span><a href="https://engage.dev3.conversations.6si.com/api/v1/unsubscribes" rel="noreferrer"><span style="white-space: pre-wrap;">here</span></a><span style="white-space: pre-wrap;">. </span></div></small>',
} as BotSchema;

export const SENDGRID_GENERIC_KB_ARTICLE_LINK =
  'https://support.6sense.com/knowledge-base/internal-only-integrating-sendgrid-with-6sense-conversational-email/';
export const SENDGRID_ERROR_KB_ARTICLE_LINK =
  'https://support.6sense.com/knowledge-base/internal-only-conversational-email-sendgrid-integration-troubleshooting-guide/';
